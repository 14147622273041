@forward "colors";

// Font
$font-family: "Source Sans Pro", "Helvetica Neue", sans-serif;

// Text sizes
$text-xxs: 12px;
$text-xs: 14px;
$text-sm: 16px;
$text-md: 18px;
$text-lg: 20px;
$text-xl: 24px;
$text-xxl: 36px;

// Spacing
$space-xxs: 3px;
$space-xs: 6px;
$space-sm: 12px;
$space-md: 24px;
$space-lg: 32px;
$space-xl: 48px;
$space-xxl: 62px;

$space-padding-x: 16px;
$space-padding-y: 10px;

// Weights
$weight-normal:   normal;
$weight-semibold: 600;
$weight-bold:     bold;

// Box shadows
$_color-box-shadow: rgba(30, 30, 30, 0.15);
$box-shadow-header: 2px 4px 8px 0 $_color-box-shadow;
$box-shadow-popover: 4px 8px 16px 0 rgba(30, 30, 30, 0.2);

// Borders
$border-width-sm: 1px;
$border-width-md: 2px;
$border-width-lg: 3px;

// Border radius
$border-radius-sm: 3px;
$border-radius-md: 6px;

// Line-heights
$line-height-lg: 1.33;
$line-height-xl: 1.5;

// page-sizes
$page-width-xxs: 734px;
$page-width-xs: 990px;
$page-width-sm: 1200px;
$page-width-md: 1500px;
$page-width-lg: 1920px;

// screen-sizes
$screen-sm: 1500px;
$screen-md: 1680px;

// expansion panel size
$expansion-panel-small: 56px;

// header height
$header-height: 2 * $space-xl;
$footer-height: $header-height;

// z-index variables
$z-index-sticky: 800;
$z-index-popover: 900;
$z-index-header: 1000;

// spider-chart size
$spider-chart-size-sm: 260px;
$spider-chart-size-md: 400px;
// The width is chosen such that the radius of the chart matches
// the radius of the KPI value share chart radius
$spider-chart-width-md: $spider-chart-size-md * 1.15;

// KPI value share size
$kpi-value-share-chart-size-md: $spider-chart-size-md * 1.2;
