@use "variables" as *;

// It removes the y padding for material dialog if not needed
.padding-0 .mat-dialog-container {
    padding: 0;
    min-height: 45px;
}

// This makes it possible to add a close icon to the material dialog.
.close-dialog.cdk-overlay-pane {
    position: relative !important;
    overflow-x: auto;
}

// This makes the backdrop in the dialog darker
.cdk-overlay-dark-backdrop {
    background-color: $backdrop-color;
    opacity: 0.85 !important;
}
