@use "variables" as *;

@font-face {
    font-family: 'Source Sans Pro';
    src: local(SourceSansPro-Regular),
    url(./source-sans-pro/SourceSansPro-Regular.ttf.woff2) format("woff2"),
    url(./source-sans-pro/SourceSansPro-Regular.ttf.woff) format("woff"),
    url(./source-sans-pro/SourceSansPro-Regular.ttf) format("truetype");
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: local(SourceSansPro-Semibold),
    url(./source-sans-pro/SourceSansPro-Semibold.ttf.woff2) format("woff2"),
    url(./source-sans-pro/SourceSansPro-Semibold.ttf.woff) format("woff"),
    url(./source-sans-pro/SourceSansPro-Semibold.ttf) format("truetype");
    font-weight: $weight-semibold;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: local(SourceSansPro-Bold),
    url(./source-sans-pro/SourceSansPro-Bold.ttf.woff2) format("woff2"),
    url(./source-sans-pro/SourceSansPro-Bold.ttf.woff) format("woff"),
    url(./source-sans-pro/SourceSansPro-Bold.ttf) format("truetype");
    font-weight: $weight-bold;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: 'Gotham';
    src: local(Gotham), url(./certificate/GothamBook.ttf) format("truetype");
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
}
