@use '@angular/material' as mat;
@use "variables" as *;
@use "input" as input;

// Style the Angular Material dropdown button for our use cases
app-form-field mat-select {
    font-size: $text-md;

    .mat-select-placeholder {
        @include input.placeholder();
    }

    .mat-select-value-text {
        font-weight: $weight-semibold;
    }
}

.mat-option {
    // This is done separately from the theming because setting the different
    // color for selected ones is only possible if the value is never null. Then
    // the selected option would have a mat-selected class as well. As our
    // default value is null and should be styled the same it will be done here.
    &:hover {
        background-color: $secondary-bright-green !important;
    }

    .mat-option-text {
        @include input.input-font-style();
    }

    &.mat-active {
        .mat-option-text {
            color: $primary-green;
        }
    }
}

@mixin style-mat-select($theme-or-config) {
    $theme-or-config: theme-with-custom-background-hover($theme-or-config, $primary-white);

    @include mat.legacy-option-color($theme-or-config);
}

@function theme-with-custom-background-hover($theme, $hover-color) {
    $theme-color: map-get($theme, color);
    $background: map-get($theme-color, background);

    $background: map_merge($background, (hover: $hover-color));
    $theme-color: map_merge($theme-color, (background: $background));
    $theme: map_merge($theme, (color: $theme-color));

    @return $theme;
}

@mixin style-mat-select-font($theme-or-config) {
    $theme-or-config: theme-with-custom-font-size($theme-or-config, $text-md);

    @include mat.legacy-option-typography($theme-or-config);
}

@function theme-with-custom-font-size($theme, $font-size) {
    $subheading-2: map-get($theme, subheading-2);
    $subheading-2: map_merge($subheading-2, (font-size: $font-size));
    $theme: map_merge($theme, (subheading-2: $subheading-2));

    @return $theme;
}
