@use '@angular/material' as mat;
@use "variables" as *;

$table-header-row-height: 36px;
$table-row-height: 60px;

// Style the angular material table
.mat-table {
    width: 100%;
    // keep border-radius in sync with the print styles
    border-radius: $border-radius-md;
    border: $border-width-sm solid $secondary-bright-grey;

    thead {
        background-color: mat.get-color-from-palette($primary, Main);
    }

    tr {
        &.mat-header-row {
            height: $table-header-row-height;
        }

        &.mat-row {
            height: $table-row-height;
        }
    }

    .mat-header-cell {
        color: mat.get-contrast-color-from-palette($primary, Main);
        text-transform: uppercase;
        vertical-align: middle;
        padding-top: 0.2em; // Needed for the header to look correctly aligned vertically in the middle
        padding-right: $space-sm;
        padding-left: $space-sm;
        white-space: nowrap;
        font-weight: bold;

        // keep border-radius in sync with the print styles
        &:first-child {
            border-top-left-radius: $border-radius-md;
        }

        &:last-child {
            border-top-right-radius: $border-radius-md;
        }
    }

    td.mat-cell {
        border: none;
        padding-right: $space-sm;
        padding-left: $space-sm;
    }

    .mat-row {
        &:nth-child(2n) {
            background-color: $secondary-bright-green;
        }

        // keep border-radius in sync with the print styles
        &:last-of-type td:first-child {
            border-bottom-left-radius: $border-radius-md;
        }

        &:last-of-type td:last-child {
            border-bottom-right-radius: $border-radius-md;
        }
    }

    .align-center {
        text-align: center;
    }

    .lastActivity {
        white-space: nowrap;
    }
}

.mat-paginator {
    background: transparent;
}

.activity-icon {
    font-size: $text-xxs;
}

// Style font-sizes for table
@mixin style-mat-table-font ($theme-or-config) {
    $theme-or-config: theme-with-custom-font-sizes-table($theme-or-config);

    @include mat.legacy-table-typography($theme-or-config);
}

@function theme-with-custom-font-sizes-table($theme) {
    $caption: map-get($theme, caption);
    $caption: map_merge($caption, (font-size: $text-xs));
    $theme: map_merge($theme, (caption: $caption));

    $body1: map-get($theme, body-1);
    $body1: map_merge($body1, (font-size: $text-sm));
    $theme: map_merge($theme, (body-1: $body1));

    @return $theme;
}

// Style font-sizes for table-pagination
@mixin style-mat-table-pagination-font ($theme-or-config) {
    $theme-or-config: theme-with-custom-font-sizes-pagination($theme-or-config);

    @include mat.legacy-paginator-typography($theme-or-config);
}

@function theme-with-custom-font-sizes-pagination($theme) {
    $caption: map-get($theme, caption);
    $caption: map_merge($caption, (font-size: $text-md));
    $theme: map_merge($theme, (caption: $caption));

    @return $theme;
}

@media print {
    .mat-table {
        border-radius: 0;

        .mat-header-cell {
            &:first-child,
            &:last-child {
                border-radius: 0;
            }
        }

        .mat-row:last-of-type td {
            &:first-child,
            &:last-child {
                border-radius: 0;
            }
        }
    }
}
