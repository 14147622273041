@use "variables" as *;
@use "mixins" as *;

@mixin user-select($value) {
    -webkit-user-select: $value;
    -moz-user-select: $value;
    -ms-user-select: $value;
    user-select: $value;
}

// Overriding default button styles like the gray background, the border, and the outline.
// Use placeholder selector and @extend to get rid of duplicates
%button-reset {
    @include user-select(none);
    cursor: pointer;
    outline: none;
    border: none;
    -webkit-tap-highlight-color: transparent;

    // The `outline: none` from above works on all browsers, however Firefox also
    // adds a special `focus-inner` which we have to disable explicitly. See:
    // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button#Firefox
    &::-moz-focus-inner {
        border: 0;
    }
}

@mixin _button-theme-by-property($property, $adjust: none) {
    &.button-green {
        @include property-with-adjusted-color($property, $adjust, $primary-green);
    }

    &.button-red {
        @include property-with-adjusted-color($property, $adjust, $secondary-notification-and-error);
    }

    &, &.button-dark-grey { // use by default
        @include property-with-adjusted-color($property, $adjust, $primary-dark-grey);
    }

    &, &.button-green, &.button-red, &.button-dark-grey {
        &[disabled] {
            @include property-with-adjusted-color($property, none, $secondary-disabled-grey);
        }
    }
}

button {
    @extend %button-reset;

    font-size: $text-md;
    font-weight: normal;
    letter-spacing: 0.1px;
    border-radius: $border-radius-md;
    padding: $space-padding-y $space-padding-x;
    box-sizing: border-box;
    border: $border-width-md solid currentColor;
    flex-shrink: 0;

    background-color: $primary-white;
    @include _button-theme-by-property('color');

    &:hover:not(:disabled) {
        @include _button-theme-by-property('background-color', 'lighter');
    }

    &:active:not(:disabled) {
        @include _button-theme-by-property('background-color', 'lightest');
    }

    &.button-primary {
        color: $primary-white !important;
        font-weight: $weight-semibold;

        @include _button-theme-by-property('background-color');
        @include _button-theme-by-property('border-color');

        &:hover:not(:disabled) {
            @include _button-theme-by-property('background-color', 'darker');
            @include _button-theme-by-property('border-color', 'darker');
        }

        &:active:not(:disabled) {
            @include _button-theme-by-property('background-color', 'darkest');
            @include _button-theme-by-property('border-color', 'darkest');
        }
    }

    .mat-progress-spinner {
        margin-right: $space-xs;
    }

    &:disabled {
        cursor: default;

        .mat-progress-spinner circle {
            stroke: $secondary-disabled-grey;
        }

        &.button-primary {
            .mat-progress-spinner circle {
                stroke: $primary-white;
            }
        }
    }
}
