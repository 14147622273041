$primary-green: #007314;
$primary-dark-green: #00400b;
$primary-bright-green: #8cb63b;
$primary-white: #fff;
$primary-dark-grey: #1e1e1e;
$primary-black: #000000;

$secondary-bright-grey: #c8c8c8;
$secondary-bright-green: #f1f8f3;
$secondary-active-bright-green: #dcf0be;
$secondary-disabled-grey: #8c8c8c;
$secondary-placeholder-grey: #787878;
$secondary-information-alert-red: #583d47;
$secondary-labels-evidence-required: #e65a00;

$secondary-notification-and-error: #a00000;

// Background colors
$background-light: $primary-white;
$background-light-grey: #f0f0f0;
$table-row-hover-color: #e0f0e4;
$table-row-highlighted-color: #ffeed4;

// Dialog colors
$backdrop-color: $primary-black;

// Badge color
$badge-brown: #aa5a0a;
$badge-dark-green: #0e501a;
$badge-dark-red: #583d47;
$badge-gray: #3c3c3c;
$badge-purple: #73325a;
$badge-blue: #329ba0;

// Progress-bar color
$progress-bar: #d2db4d;

// Sustainability colors
$sustainability-negative: #e65032;
$sustainability-neutral: #e7af01;
$sustainability-positive: #8db63a;
$sustainability-very-positive: #28b428;

// Feedback item table
$feedback-positive: $sustainability-very-positive;
$feedback-negative: $sustainability-negative;

// State box colors
$state-box-warning: rgba(253, 195, 0, 0.3);

// Measure block
$measure-block-header-background-color: #f5f5f5;

// Certification card
$highlighted-certification-card-background-color: #e7f3e9;
