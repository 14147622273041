@use "variables" as *;

.mat-checkbox .mat-checkbox-layout {
    align-items: center;
}

.mat-checkbox-inner-container {
    width: $text-xxl !important;
    height: $text-xxl !important;
}

.mat-checkbox-frame {
    border-color: transparent !important;
}

.mat-checkbox-background {
    border-radius: $border-radius-md !important;
    border: $border-width-md solid $secondary-bright-grey !important;
    background-color: $primary-white;
}

mat-checkbox.alert {
    .mat-checkbox-frame {
        background-color: mix($secondary-notification-and-error, white, 10%);
    }

    .mat-checkbox-background {
        border-color: $secondary-notification-and-error !important;
        background-color: mix($secondary-notification-and-error, white, 10%) !important;
    }

    &.mat-checkbox-checked {
        .mat-checkbox-background {
            background-color: $secondary-notification-and-error !important;
        }
    }
}

@media print {
    .mat-checkbox-checked.mat-primary .mat-checkbox-background {
        border-color: $primary-green !important;
    }

    .mat-checkbox-inner-container {
        width: $text-xl !important;
        height: $text-xl !important;
    }
}
