// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
@use 'variables' as *;
@import 'themes';
@import './assets/fonts/fonts';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-legacy-component-themes($app-theme);
@include mat.all-legacy-component-typographies($app-typography);

// needs to come after import of material styles as it might use mixins from material theme
@import 'blocks/button';
@import "blocks/checkbox";
@import "blocks/dialog";
@import "blocks/input";
@import "blocks/mat-menu-item";
@import "blocks/select";
@import "blocks/table";
@import "blocks/expansion-panel";
@import "blocks/tooltip";

@include style-mat-select($app-theme);
@include style-mat-select-font($app-typography);
@include style-mat-table-font($app-typography);
@include style-mat-table-pagination-font($app-typography);
@include style-mat-expansion-panel-color($app-theme);
@include style-mat-expansion-panel-font($app-typography);

/* You can add global styles to this file, and also import other style files */
html, body {
    height: 100%;
    margin: 0;
    padding: 0;
}

* {
    font-family: $font-family;
}

body {
    color: $primary-dark-grey;
}

a {
    color: $primary-green;
    text-decoration: underline;
    font-weight: $weight-semibold;
    cursor: pointer;

    &:hover {
        color: $primary-dark-green;
    }
}

strong, b {
    font-weight: $weight-semibold;
}

/**
This is a bugfix for the evaluation page where chrome tries to keep the scroll
position when we adapt the content on the page dynamically. Because of this the
window jumps to weird positions. To tell chrome it shouldn't try to keep the
scroll position we can use this class to append on the body.
 */
body.no-anchor-scroll {
    overflow-anchor: none;
}
