@use "variables" as *;

// Remove styles from chrome autofill
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
    transition: background-color 5000s;
}

@mixin input-font-style {
    font-size: $text-md;
    font-weight: $weight-semibold;
    color: $primary-dark-grey;
}

input,
textarea {
    width: 100%;
    @include input-font-style();
    outline: none;
    border: none;
    background-color: transparent;
    padding: 0;

    &:disabled {
        color: $secondary-bright-grey;
    }
}

textarea {
    resize: none;
}

@mixin placeholder {
    color: $secondary-placeholder-grey;
    opacity: 1;
    font-weight: normal;
}

::placeholder {
    @include placeholder();
}

// add styles for input with icon
.input-icon {
    border: none;
    outline: none;
    padding-left: $space-md;
}

// hide the edge password reveal icon
::-ms-reveal {
    display: none;
}
