@use "variables" as *;

$darkerAmount: 10%;
$darkestAmount: 15%;
$lighterAmount: 10%;
$lightestAmount: 15%;

@mixin property-with-adjusted-color($property, $adjust, $color) {
    @if $adjust == darker {
        #{$property}: darken($color, $darkerAmount);
    }
    @else if $adjust == darkest {
        #{$property}: darken($color, $darkestAmount);
    }
    @else if $adjust == lighter {
        #{$property}: mix($color, white, $lighterAmount)
    }
    @else if $adjust == lightest {
        #{$property}: mix($color, white, $lightestAmount)
    }
    @else {
        #{$property}: $color;
    }
}

@mixin for-screen-sm {
    @media (max-width: $screen-sm) { @content; }
}

@mixin for-screen-md {
    @media (max-width: $screen-md) { @content; }
}
