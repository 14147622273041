@use '@angular/material' as mat;
@use "variables" as *;

.mat-accordion {
    // This selector is extended to override default styles without using !important
    mat-expansion-panel.mat-expansion-panel {
        box-shadow: none;

        &.bordered {
            border: $border-width-sm solid $secondary-bright-grey;

            &:not(:first-of-type) {
                border-top-width: 0;
            }

            &:first-of-type {
                border-top-right-radius: $border-radius-md;
                border-top-left-radius: $border-radius-md;
            }

            &:last-of-type {
                border-bottom-right-radius: $border-radius-md;
                border-bottom-left-radius: $border-radius-md;
            }

            .mat-expansion-panel-header {
                &.mat-expanded {
                    border-bottom: $border-width-sm solid $secondary-bright-grey;
                }
            }
        }
    }

    .mat-expansion-panel-header,
    .mat-expansion-panel-body {
        // These elements are set without padding to be able to place the component
        // inside on different positions depending on the screen size
        padding: 0;
    }
}

@mixin style-mat-expansion-panel-color($theme-or-config) {
    $theme-or-config: theme-with-custom-header-color($theme-or-config);

    @include mat.expansion-color($theme-or-config);
}

@function theme-with-custom-header-color($theme) {
    $theme-color: map-get($theme, color);
    $foreground: map-get($theme-color, foreground);

    $foreground: map_merge($foreground, (text: $primary-dark-grey));
    $theme-color: map_merge($theme-color, (foreground: $foreground));
    $theme: map_merge($theme, (color: $theme-color));

    @return $theme;
}

@mixin style-mat-expansion-panel-font($theme-or-config) {
    $theme-or-config: theme-with-custom-font-sizes($theme-or-config);

    @include mat.expansion-typography($theme-or-config);
}

@function theme-with-custom-font-sizes($theme) {
    $subheading: map-get($theme, subheading-1);

    $subheading: map_merge($subheading, (font-size: $text-sm));
    $subheading: map_merge($subheading, (font-weight: $weight-semibold));

    $theme: map_merge($theme, (subheading-1: $subheading));

    @return $theme;
}
