@use "variables" as *;

button {
    &.mat-menu-item {
        color: $primary-dark-grey;
        font-size: $text-sm;
        line-height: $line-height-xl;
        font-weight: $weight-semibold;

        &:hover {
            background-color: $secondary-bright-green;
            color: $primary-green;
        }
    }
}
